import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useIntl } from 'react-intl';
import HiddenLoader from '@fuww/library/src/HiddenLoader';
import withQuery from '../../../../lib/withQuery';
import messages from '../../../../lib/messages.mjs';
import articlesQuery from '../../../../lib/queries/news/articlesQuery.graphql';
import getArticlesField from '../../../../lib/queries/news/getArticlesField';
import spotlightArticlesQuery
from '../../../../lib/queries/news/spotlightArticlesQuery.graphql';
import getNewsboardFieldVariables
from '../../../../lib/queries/getNewsboardFieldVariables';
import { spotlightVariables } from
'../../../../lib/queries/news/variables/featured';
import { featuredCategoryIds } from '../../../../lib/mainCategoryIds';
import { useSiteContext } from '../../../SiteContext';
import getFeaturedVariables from '../../../../lib/getFeaturedVariables';
import loading from '../../../../lib/loading';

const FeaturedSection = dynamic(() => import('./FeaturedSection'), {
  ssr: true,
  loading,
});

const AdditionalNewsSections = dynamic(
  () => import('../AdditionalNewsSections'), {
    ssr: false,
    loading,
  },
);

const Top = ({
  data,
  variables,
  field,
  spotlightNewsArticle,
  showCreatedAt,
  expandedSections,
  moreNewsVariables,
  showLoader,
  categorySection,
}) => {
  const intl = useIntl();

  const dataFieldArticles = Array.isArray(data?.[field]) ? data[field] : [];

  const featuredArticles = spotlightNewsArticle
    ? [spotlightNewsArticle, ...dataFieldArticles]
    : dataFieldArticles;

  const notInIds = featuredArticles.map(
    (item) => item?.id,
  ).filter((id) => id != null);

  return (
    <>
      <FeaturedSection
        title={intl.formatMessage(messages.featured)}
        variables={variables}
        featuredArticles={featuredArticles}
        showCreatedAt={showCreatedAt}
        showLoader={showLoader}
      />
      <AdditionalNewsSections
        variables={variables}
        moreNewsVariables={moreNewsVariables}
        notInIds={notInIds}
        showCreatedAt={showCreatedAt}
        expandedSections={expandedSections}
        showLoader={showLoader}
        categorySection={categorySection}
      />
    </>
  );
};

Top.propTypes = {
  data: PropTypes.shape({}),
  variables: PropTypes.shape({}).isRequired,
  moreNewsVariables: PropTypes.shape({}),
  field: PropTypes.string.isRequired,
  spotlightNewsArticle: PropTypes.shape({}),
  expandedSections: PropTypes.bool.isRequired,
  showCreatedAt: PropTypes.bool,
  showLoader: PropTypes.bool,
  categorySection: PropTypes.node,
};

Top.defaultProps = {
  data: {},
  moreNewsVariables: null,
  spotlightNewsArticle: null,
  showCreatedAt: false,
  showLoader: true,
  categorySection: null,
};

const TopNewsWithQuery = withQuery(Top);
const errorMessage = 'Error loading news articles...';

const TopNewsSection = ({
  variables,
  showCreatedAt,
  expandedSections,
  moreNewsVariables,
  categorySection,
}) => {
  const { prefix, hasLocalNews, newsboard } = useSiteContext();
  const articleField = getArticlesField(prefix, hasLocalNews);
  const featuredSpotlightVariables = {
    ...getNewsboardFieldVariables(prefix, hasLocalNews),
    ...spotlightVariables,
    newsboards: [newsboard],
    categoryIds: featuredCategoryIds,
    ...variables,
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const TopNewsWithData = ({ data, showLoader }) => {
    const tag = data?.tag ?? {};
    const spotlightNewsArticle = tag[articleField]?.[0] || null;
    const notInIds = spotlightNewsArticle ? [spotlightNewsArticle.id] : [];

    const articlesField = getArticlesField(prefix, hasLocalNews);

    return (
      <TopNewsWithQuery
        query={articlesQuery}
        variables={getFeaturedVariables(
          prefix,
          hasLocalNews,
          newsboard,
          notInIds,
          variables,
        )}
        skip={showLoader}
        errorMessage={errorMessage}
        field={articlesField}
        spotlightNewsArticle={spotlightNewsArticle}
        showCreatedAt={showCreatedAt}
        expandedSections={expandedSections}
        moreNewsVariables={moreNewsVariables}
        categorySection={categorySection}
        loader={<HiddenLoader />}
        renderAlways
      />
    );
  };

  TopNewsWithData.propTypes = {
    data: PropTypes.shape({ tag: PropTypes.shape({}) }),
    showLoader: PropTypes.bool,
  };

  TopNewsWithData.defaultProps = {
    data: {},
    showLoader: false,
  };

  const TopNewsWithDataWithSpotlightQuery = withQuery(TopNewsWithData);

  return (
    <TopNewsWithDataWithSpotlightQuery
      query={spotlightArticlesQuery}
      variables={featuredSpotlightVariables}
      errorMessage={errorMessage}
      hasLocalNews={hasLocalNews}
      loader={<HiddenLoader />}
      renderAlways
    />
  );
};

TopNewsSection.propTypes = {
  variables: PropTypes.shape({}),
  moreNewsVariables: PropTypes.shape({}),
  expandedSections: PropTypes.bool.isRequired,
  showCreatedAt: PropTypes.bool,
  categorySection: PropTypes.node,
};

TopNewsSection.defaultProps = {
  variables: null,
  moreNewsVariables: null,
  showCreatedAt: false,
  categorySection: null,
};

export default TopNewsSection;
