import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { DocumentNode } from 'graphql';
import {
  WatchQueryFetchPolicy, Context, QueryResult, useQuery, NetworkStatus,
} from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import Button from '@fuww/library/src/Button';
import ReplayIcon from '@fuww/library/src/Icons/Replay';
import ErrorWrapper from '@fuww/library/src/ErrorWrapper';
import Loader from '../components/Loader';
import Error from '../components/Error';
import messages from './messages.mjs';

export const getLoading = (
  clientLoading: boolean,
  setLoadingOnPoll: boolean,
  networkStatus : NetworkStatus,
) => (
  typeof window === 'undefined'
    ? true
    : (!setLoadingOnPoll && networkStatus === NetworkStatus.poll
      ? false : clientLoading)
);

const wrappedComponentPropertyTypes = {
  query: PropTypes.shape({}).isRequired,
  variables: PropTypes.shape({}),
  context: PropTypes.shape({}),
  errorMessage: PropTypes.string.isRequired,
  loader: PropTypes.element,
  showLoaderWhenSetVariables: PropTypes.bool,
  showLoaderWhenDataIsEmpty: PropTypes.bool,
  renderAlways: PropTypes.bool,
  skip: PropTypes.bool,
  ssr: PropTypes.bool,
  passErrors: PropTypes.arrayOf(PropTypes.string),
  fetchPolicy: PropTypes.string,
  notifyOnNetworkStatusChange: PropTypes.bool,
  showLoader: PropTypes.bool,
};

type WrappedComponentProperties = InferProps<
typeof wrappedComponentPropertyTypes
> & {
  query: DocumentNode;
  variables?: Record<string, unknown>;
  context?: Context;
  ssr?: boolean;
  passErrors?: string[];
  fetchPolicy?: WatchQueryFetchPolicy;
  notifyOnNetworkStatusChange?: boolean;
  pollInterval?: number;
  setLoadingOnPoll?: boolean;
  showLoader?: boolean;
  skip?: boolean;
};

export type WithDataProperties = Pick<QueryResult, (
  'error' | 'fetchMore' | 'loading' | 'variables'
)>;

export type WithQueryProperties<T> = Omit<
T, 'data' | 'fetchMore' | 'loading' | 'variables'
> & WrappedComponentProperties;

const withQuery = <T extends object>(
  Component: React.ComponentType<T>,
) => {
  const WrappedComponent = ({
    query,
    variables = {},
    errorMessage,
    context = {},
    loader = <Loader />,
    showLoaderWhenSetVariables = true,
    showLoaderWhenDataIsEmpty = false,
    renderAlways = false,
    skip = false,
    ssr = true,
    passErrors = [],
    fetchPolicy = 'cache-and-network',
    notifyOnNetworkStatusChange = true,
    pollInterval,
    setLoadingOnPoll = false,
    ...rest
  }: WithQueryProperties<T>) => {
    const {
      loading: clientLoading, error, data, fetchMore, networkStatus, refetch,
    } = useQuery<T>(query, {
      variables,
      context,
      ssr,
      fetchPolicy,
      notifyOnNetworkStatusChange,
      pollInterval,
      skip,
    });
    const loading = getLoading(clientLoading, setLoadingOnPoll, networkStatus);
    const intl = useIntl();

    if (
      error
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      && (!error.graphQLErrors || isEmpty(
        error.graphQLErrors
          .filter(({ message }) => passErrors.includes(message)),
      ))
      && isEmpty(data)
    ) {
      return (
        <ErrorWrapper>
          <Error error={errorMessage} />
          <Button
            startIcon={<ReplayIcon />}
            onClick={() => refetch()}
          >
            {intl.formatMessage(messages.retryLabel)}
          </Button>
        </ErrorWrapper>
      );
    }

    // if there was a change in the variables (in default case) or
    // it's loading and there is no data yet
    const showLoader = (showLoaderWhenSetVariables
      && networkStatus === NetworkStatus.setVariables)
    || (loading && isEmpty(data))
    || (showLoaderWhenDataIsEmpty && isEmpty(data))
    || skip;

    return (
      <>
        {showLoader && loader}
        {(!showLoader || renderAlways) && (
        <Component
          data={data}
          loading={loading}
          fetchMore={fetchMore}
          variables={variables}
          error={error}
          showLoader={showLoader}
          refetch={refetch}
          skip={skip}
          {...rest as T}
        />
        )}
      </>
    );
  };

  WrappedComponent.propTypes = wrappedComponentPropertyTypes;

  return WrappedComponent;
};

export default withQuery;
